.top-banner {
    background: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding-left: 69px;
}
.top-banner h1 {
    font-family: 'Circe',sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    color: #2C2A29;
    margin: 46px 0 0 0;
    text-transform: uppercase;
}

.top-banner h1:first-line {
    color: #E04E39;
}

.top-banner__numbers {
    margin-bottom: 57px;
}

.top-banner__numbers-title {
    font-family: 'Circe',sans-serif;
    font-size: 20px;
    line-height: 29px;
    color: #ED5338;
}

.top-banner__numbers-description {
    font-family: 'Circe',sans-serif;
    font-weight: normal;
    font-size: 15px;
    line-height: 13px;
    color: #2C2A29;
}
.bg-1 {
    margin-top: -23px;
    padding-top: 23px;
    background: url(../images/bg-1.png) top right no-repeat;
}
.top-banner__numbers-title span {
    font-weight: bold;
    font-size: 30px;
    line-height: 44px;
}
.my-container {
    max-width: 1004px;
    margin: 0 auto;
}
.map-block {
    text-align: right;
    margin: 11px 16px 0 0;
}
.separator--banner {
    height: 30px;
}
.sub-banner__inner {
    border-radius: 10px;
    width: 290px;
    height: 155px;
    padding: 23px;
    padding-right: 0;
}
.cornered {
    background: white url(../images/cornered.png) top right no-repeat;
    background-size: contain;
}

.sub-banner__title {
    font-family: Circe, sans-serif;
    font-weight: 800;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: -0.025em;
    margin-bottom: 5px;
}

.sub-banner__description {
    font-family: Circe, sans-serif;
    font-size: 16px;
    line-height: 18px;
}

.sub-banner__description a {
    color: #ED5338;
    font-weight: bold;
}
.separator--bg-1 {
    height: 61px;
}
.bg-2 {
    padding-top: 61px;
    margin-top: -61px;
    background: url(../images/bg-2.png) top left no-repeat;
}

.capabilities__inner {
    background: #DCDADB url(../images/capabilities-bg.png) top left no-repeat;
    border-radius: 10px;
    background-size: contain;
    padding: 42px 100px 78px;
}

.top-sub-banner:nth-child(2) .sub-banner__inner {
    margin: 0 auto;
}

.top-sub-banner:nth-child(3) .sub-banner__inner {
    margin-left: auto;
}

.capabilities__header {
    font-family: Circe, sans-serif;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    color: #E04E39;
    margin: 0 0 7px 0;
    padding: 0;
}

.capabilities__description {
    text-align: center;
    font-size: 21px;
    line-height: 29px;
    margin-bottom: 43px;
}

.capability {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 7px 57px 16px;
    height: 100%;
}

.capability__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 29px;
}

.capability__description {
    font-size: 16px;
    line-height: 21px;
}

.dotted {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.dotted li:not(:last-child) {
    margin-bottom: 21px;
}

.dotted li {
    position: relative;
    padding-left: 33px;
}

.dotted li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FACBC3;
}

.dotted li:after {
    background: #F17762;
    content: '';
    position: absolute;
    left: 4px;
    top: 9px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.separator--bg-2 {height: 52px;}

.orders__header {
    font-family: Circe,sans-serif;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 44px;
}

.orders__filter-choice {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 50px;
}

.red-button {
    background: #ED5338;
    border-radius: 1000px;
    color: white!important;
    text-decoration: none!important;
}

.orders__filter-all {
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
    line-height: 32px;
}

.red-button:hover {
    background: #5E2D1D;
}

.orders__filter-accept {
    width: 121px;
    height: 37px;
    border: 1px solid #ED5338;
    border-radius: 1000px;
    background: transparent;
    margin-bottom: 35px;
    font-size: 15px;
    line-height: 19px;
    color: #ED5338;
    cursor: pointer;
}

.orders__filter-accept:hover {
    background: #ED5338;
    color: white;
    outline: none!important;
}

.orders__filter-clear {
    border: 0;
    background: none;
    padding: 0;
    outline: none!important;
}

.orders__filter-clear:hover {
    text-decoration: underline;
    cursor: pointer;
}

.orders__filter-footer {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-top: 60px;
}

.orders__filter-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #ED5338;
}

.orders__filter-all--header {
    font-size: 14px;
    line-height: 18px;
    color: #000000!important;
}

.orders__filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
.orders__filter-categories {
    margin-bottom: 27px;
}

.orders__filter-category {
    margin-bottom: 27px;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.orders__filter-sub-category {
    margin-bottom: 27px;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.orders__filter-sub-categories {
    margin-bottom: 27px;
}



.orders__filter-category span:last-child,
.orders__filter-sub-category span:last-child{
    font-size: 19px;
    line-height: 25px;
}
.orders__filter-categories {
    margin-bottom: 27px;
}

.orders__filter-category {
    margin-bottom: 27px;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.orders__filter-sub-category {
    margin-bottom: 27px;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.orders__filter-sub-categories {
    margin-bottom: 27px;
}



.orders__filter-category span:last-child {
    font-size: 19px;
    line-height: 25px;
}

.modal-header {
    font-family: Circe,sans-serif;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    justify-content: center;
    text-transform: uppercase;
}

.modal-footer {
    justify-content: flex-start;
}

.modal-footer button {
    background: #ed5338;
    border: none;
    border-radius: 1000px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    width: 200px;
    height: 52px;
}

.modal-footer button:hover {
    background: #5E2D1D;
}

.category-variant,
.sub-category-variant{
    border-color: #ED5339;
    color: #ed5338;
    box-shadow: none!important;
}
.orders__filter-categories button,
.orders__filter-sub-categories button {
    margin-right: .25rem!important;
}
.category-variant:hover,.category-variant.active,
.sub-category-variant:hover,.sub-category-variant.active{
    background: #ed5338!important;
    color: white;
    border-color: #ed5338!important;
}
.orders__list-item {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px 20px 30px;
    margin-bottom: 40px;
}

.orders-item__image-block {
    margin-bottom: 20px;
}

.orders-item__name {
    font-family: Circe,sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #000000!important;
    margin-bottom: 8px;
}
.orders-item__description {
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 17px;
}

.orders-item__date {
    font-size: 14px;
    line-height: 18px;
    color: #676262;
    margin-bottom: 9px;
}

.orders-item__placement {
    font-size: 14px;
    line-height: 18px;
    color: #676262;
    margin-bottom: 22px;
}

.orders-item__stats {
    font-size: 14px;
    line-height: 18px;
    color: #676262;
    margin-bottom: 25px;
}

.orders-item__others a {
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.orders__add {
    margin-top: 10px;
    display: flex;
    width: 321px;
    height: 68px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    font-size: 25px;
    line-height: 32px;
    justify-content: center;
    align-items: center;
}

.separator--orders {
    height: 71px;
}
.white-block {
    background: white;
}

.specialization {
    padding: 59px 0 64px;
}

.specialization__header {
    font-family: Circe, sans-serif;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 11px;
}

.specialization__description {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 44px;
}

.specialization__description a {
    font-weight: bold;

    /* identical to box height */
    color: #ED5338;
}

.specialization__category {
    border-radius: 10px;
    height: 207px;
    background-color: rgba(0,0,0,.5);
    padding: 58px 20px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: 100% auto;
    background-position: top center;
    background-blend-mode: color;
}

.specialization__category-link {
    border: 1px solid #FFFFFF;
    border-radius: 1000px;
    width: 120px;
    height: 33px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    line-height: 22px;
    color: #FFFFFF!important;
    text-decoration: none!important;
    display: none;
}

.specialization__category-name {
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    color: #FFFFFF!important;
    text-align: center;
}

.specialization__category:hover .specialization__category-link {
    display: flex;
}
.specialization__category-link:hover{
    background: #fff;
    color: #ED5338!important;
}
.specialization__category:hover {
    background: #ED5338!important;
}
.specialization__members {
    margin-top: 60px;
    width: 366px;
    height: 60px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
}
.about {
    padding: 44px 0 55px;
    font-size: 16px;
    line-height: 21px;
}

.about__header {
    font-family: Circe, sans-serif;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 49px;
}

.about p {
    margin-bottom: 21px;
}

.about p:last-child {
    margin-bottom: 0;
}

.carousel-block {
    border-radius: 5px;
    padding: 56px 0 67px;
}
.customers-slide div {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

.carousel-slide {
    margin-top: 10px;
    margin-bottom: 20px;
    outline: none!important;
}
.carousel__header {
    font-family: Circe, sans-serif;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 50px;
}
.carousel-slide {
    height: 110px;
    padding: 0 15px;
}
.developers-slide div{
    background: #E1E1E1;
    border-radius: 5px;
}
.carousel-slider {
    margin-bottom: 54px;
}
.slick-prev {
    background: url(../images/slider-arrow.png) center center no-repeat!important;
    transform: rotate(180deg) translate(0, 50%);
}

.slick-arrow:before {
    display: none;
}

.slick-next {
    background: url(../images/slider-arrow.png) center center no-repeat!important;
}

.slick-arrow:hover {
    background-image: url(../images/slider-arrow-hover.png) !important;
}
.copy {
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    color: #5E5E5E;
}

.pp {
    font-size: 12px;
    line-height: 16px;
    color: #5E5E5E!important;
    display: block;
    text-align: center;
}

.illuminator {
    font-size: 12px;
    line-height: 16px;
    color: #5E5E5E!important;
    display: block;
    text-align: right;
}

footer {
    padding: 15px 0 23px;
}
.footer-menu a.active {
    color: #ED5338;
}
.footer__logo-block {
    margin-bottom: 21px;
}

.footer__phone {
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #2C2A29!important;
}

.footer-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
}

.footer-menu a {
    font-family: Circe, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.025em;
    color: #2C2A29;
    text-transform: uppercase;
    margin-left: 65px;
}
.soc-container {
    margin-top: 23px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.soc-link:nth-child(2) {
    margin: 0 20px;
}

.soc-link:nth-child(1) {
    margin-bottom: -5px;
}
.footer__top-line {
    margin-bottom: 37px;
}
.tp-form input,.tp-form textarea {
    padding: 5px 10px;
    margin-bottom: 5px;
    border-color: #F1F1F1;
    border-style: solid;
}

.tp-form input[type="file"] {
    display: none;
}

.tp-form label {
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 7px 15px;
    color: #fff;
    background: #ed5338;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.tp-form label:hover {
    background: #5E2D1D;
}

.tp-form textarea {
    resize: vertical;
}

.tp-form input[type="submit"] {
    background: #5e2d1d;
    color: white;
    font-weight: bold;
    margin: 0;
    border-radius: 5px;
    cursor: pointer;
    border: 0;
    padding: 7px 15px;
}

.tp-form input[type="submit"]:hover {
    background: #ed5338;
}
button.tp {
    outline: none;
}

span.file-name {
    font-size: 12px;
    line-height: 16px;
    color: #5E5E5E;
}
.tp-form textarea {
    min-height: 100px;
    resize: vertical;
}
span.file-size {
    font-size: 12px;
    line-height: 16px;
    color: #5E5E5E;
    margin-bottom: 5px;
}
.company-header__inner {
    padding: 35px 30px;
    height: 273px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.verified {
    margin-top: 22px;
}

.verified img {
    float: left;
    margin-right: 18px;
    margin-top: 2px;
}

.verified-text {
    font-size: 12px;
    line-height: 16px;
    color: #484747;
}

.hash-tag {
    font-size: 15px;
    line-height: 18px;
    color: #484747;
    margin-bottom: 30px;
}

.company-name {
    font-family: Circe, sans-serif;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 19px;
    text-transform: uppercase;
}

span.information-value {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #E04E39;
}

.company__general-information {
    font-size: 16px;
    line-height: 21px;
}

.company__general-information a {
    color: #2C2A29;
    display: flex;
    align-items: center;
}

.company__general-information:not(:last-child) {
    margin-bottom: 32px;
}

.hash-tags {
    height: 164px;
    overflow: auto;
    margin-top: 39px;
    float: right;
    padding-right: 10px;
}

.company-header__right-side {
    overflow: auto;
}
.mnw-538{
    min-width: 538px;
}
.mw-180{
    max-width: 180px;
}
.mnw-210 {
    min-width: 210px;
}
.company__general-information svg {
    margin-right: 12px;
}
.company__gallery .slick-slide, .capacity-slider .slick-slide{
    outline: none!important;
}
.contact-the-company {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    border: 0;
    width: 220px;
    height: 43px;
    padding: 0;
    cursor: pointer;
    outline: none!important;
}

.add-company-to-favorite {
    border: 0;
    background: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    outline: none!important;
}

.add-company-to-favorite:hover {
    text-decoration: underline;
}
.scrollspy-block {
    margin-top: 30px;
}

div#list-example {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    position: sticky;
    top: 0;
}

.list-group-item {
    border-color: rgba(222, 222, 222, 0.3);
    font-size: 16px;
    line-height: 20px;
    color: #2C2A29;
    padding: 15px 15px 15px 30px;
}

.list-group-item.active {
    background: none;
    border-color: transparent;
    color: #ed5338;
}
.scrollspy__item-1 {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    padding: 30px 30px 40px;
    margin-bottom: 30px;
}

.scrollspy__item-other {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 30px 30px 40px;
    margin-bottom: 50px;
}
.company-slider-arrow--prev {
    width: 14px;
    height: 14px;
    border: none;
    margin: 20px 0;
    background: url(../images/slider-arrow.png) center center no-repeat;
    transform: rotate(180deg);
    cursor: pointer;
    outline: none!important;
}

button.company-slider-arrow--next {
    width: 14px;
    height: 14px;
    border: none;
    margin: 20px 0;
    background: url(../images/slider-arrow.png) center center no-repeat;
    cursor: pointer;
    outline: none!important;
}

.company-slider-arrows {
    display: flex;
    justify-content: space-between;
}

.company-video iframe {
    width: 100%;
    height: auto;
    border: none;
}

.company-general {
    font-size: 16px;
    line-height: 26px;
    overflow: auto;
    padding-right: 15px;
}
.table--general {
    margin-top: 40px;
}

.table--general td, .table--general th {
    padding: 12px 12px 22px;
    font-size: 13px;
    line-height: 14px;
    font-weight: normal;
    width: 25%;
    border: 0;
}

.table--general {
    border: 1px solid #cccccc33;
}

.table--general td:not(:last-child),.table--general th:not(:last-child) {
    border-right: 1px solid #cccccc33;
}

.table--general tr:not(:last-child)>* {
    border-bottom: 1px solid #cccccc33;
}

.table--general th {
    background: #F8F5F5;
}
.company-h2 {
    font-family: Circe, sans-serif;
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    margin-bottom: 21px;
    text-align: center;
    text-transform: uppercase;
}
.capacity-slider {
    max-width: 517px;
    margin: 0 auto 45px;
}

.capacity-slider .slick-prev {
    left: -55px;
}

.capacity-slider .slick-next {
    right: -55px;
}
.company-h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 21px;
}

.full-width-row {
    margin: 0 -31px;
}

.equipment-header {
    padding-left: 31px;
}

.table--equipment th, .table--equipment td {
    padding: 10px 31px 10px 31px;
}

.table--equipment th:last-child, .table--equipment td:last-child {
    width: 95px;
    text-align: center;
}

.table--equipment thead {
    font-size: 16px;
    line-height: 21px;
}

.table td {
    font-size: 14px;
    line-height: 18px;
}
.full-width-row .col-xl-6:first-child {
    padding-right: 0;
    margin-left: 1px;
}

.full-width-row .col-xl-6:last-child {
    padding-left: 0;
    margin-left: -1px;
}

.table--equipment {
    margin-bottom: 35px;
}
.table--equipment * {
    border: 0!important;
}

.table--equipment thead {
    background: #F8F5F5;
}

.table--equipment tbody tr:nth-child(2n) {
    background: #F8F5F5;
}
.table--equipment th:last-child {
    padding-left: 15px;
}

.look-more-table {
    display: inline-block;
    font-size: 16px;
    line-height: 21px;
    color: #4E4E4E;
    margin-top: 10px;
    cursor: pointer;
    border-bottom: 1px solid #4e4e4e80!important;
}
.company-buildings {
    margin-bottom: 35px;
}

.company-product {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 29px;
}

.company-product:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.company-product-name {
    top: 50%;
    color: white;
    z-index: 2;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    left: 0;
    right: 0;
    text-transform: uppercase;
    padding: 0 30px;
    transform: translateY(-50%);
}
.company-quality {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 35px;
}

.company-patent {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 35px;
}
@media (min-width: 1441px){
    .bg-1 {
        background-position: top right calc(50vw - 720px);
    }

    .bg-2 {
        background-position: top left calc(50vw - 720px);
    }
}

@media (max-width: 1199px) {
    .top-banner h1 {
        font-size: 22px;
        line-height: 28px;
    }
    .capabilities__inner{
        padding: 42px 52px 78px;
    }
    .footer-menu a{
        margin-left: 28px;
    }
    .mnw-210,.mnw-538{
        min-width: unset;
    }
    .table--general td, .table--general th {
        padding: 10px 10px 15px;
    }

    .company-name {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 15px;
    }

    span.information-value {
        font-size: 16px;
        line-height: 21px;
    }

    .company__general-information {
        font-size: 14px;
    }

    .hash-tag {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
    }

    .hash-tags {
        height: 150px;
        overflow: auto;
        margin-top: 41px;
    }
    .list-group-item {
        padding: 10px 10px 10px 15px;
    }

    .capacity-slider .slick-prev {
        left: -35px;
    }

    .capacity-slider .slick-next {
        right: -35px;
    }
    .scrollspy__item-1 {
        padding: 20px 15px 30px;
    }
    .scrollspy__item-other {
        padding: 20px 15px 30px;
    }

    .full-width-row {
        margin: 0 -16px;
    }

    .equipment-header {
        padding-left: 16px;
    }

    .table--equipment th, .table--equipment td {
        padding: 10px 16px 10px 16px;
    }

    .table--equipment thead {
        font-size: 14px;
        line-height: 16px;
    }

    .table--equipment th:last-child, .table--equipment td:last-child {
        width: 75px;
    }
    .look-more-table {
        font-size: 14px;
        line-height: 16px;
    }

    .company-buildings {
        font-size: 14px;
        line-height: 16px;
    }
    .company-product-name {
        font-size: 14px;
        line-height: 16px;
    }
}
@media (max-width:991px){
    .top-banner {
        padding-left: 15px;
        padding-right: 15px;
    }
    .top-banner h1 {
        font-size: 20px;
        line-height: 26px;
        margin-top: 0;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
    }

    .map-block {
        margin-right: 0;
        margin-top: 0;
    }

    .map-block svg {
        max-width: 100%;
    }
    .sub-banner__inner {
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .sub-banner__title {
        font-size: 16px;
        line-height: 24px;
    }

    .sub-banner__description {
        font-size: 14px;
        line-height: 16px;
    }
    .capabilities__header {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 5px;
    }

    .capabilities__description {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
    }
    .capabilities__inner {
        padding: 25px 30px 40px;
    }

    .capability {
        padding: 15px 15px 30px 15px;
    }

    .capability__title {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 15px;
    }

    .dotted li:not(:last-child) {
        margin-bottom: 10px;
    }

    .bg-2 {
        padding-top: 40px;
        margin-top: -40px;
    }

    .separator--bg-1 {
        height: 40px;
    }

    .separator--bg-2 {
        height: 40px;
    }

    .orders__header {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 30px;
    }
    .orders__filter-choice {
        padding: 15px;
        margin-bottom: 30px;
    }

    .orders__filter-header {
        margin-bottom: 15px;
    }


    .orders__filter-categories:empty,.orders__filter-sub-categories:empty {
        display: none;
    }

    .orders__filter-footer {
        margin-top: 15px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .orders__filter-accept {
        margin-bottom: 0;
    }

    .orders__filter-category,
    .orders__filter-categories,
    .orders__filter-sub-category,
    .orders__filter-sub-categories {
        margin-bottom: 15px;
    }
    .orders__filter {
        margin-bottom: 30px;
    }

    .orders__filter-all {
        font-size: 20px;
        line-height: 27px;
        height: 42px;
        max-width: 270px;
    }

    .orders__list-item {
        margin-bottom: 30px;
    }

    .orders__add {
        margin-top: 0;
        max-width: 270px;
        height: 42px;
        font-size: 20px;
        line-height: 27px;
    }

    .separator--orders {
        height: 40px;
    }

    .specialization {
        padding: 30px 0 40px;
    }

    .specialization__members {
        max-width: 270px;
        font-size: 16px;
        line-height: 22px;
        height: 42px;
        margin-top: 0;
    }

    .specialization__header {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    .specialization__description {
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 30px;
    }
    .specialization__category {
        margin-bottom: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        height: 261px;
    }
    .about {
        padding: 30px 0 40px;
    }

    .about__header {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 30px;
    }
    .about p {
        margin-bottom: 16px;
    }
    .carousel-block {
        padding: 30px 0;
    }

    .carousel__header {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 30px;
    }

    .carousel-slider {
        margin-bottom: 15px;
    }
    .developers-slider{
        margin-bottom: 20px;
    }
    .customers-slide div {
        padding: 5px;
    }
    .footer-menu {
        flex-wrap: wrap;
    }

    .footer-menu li:nth-last-child(n+4) {
        margin-bottom: 5px;
    }

    .footer-menu a {
        margin-left: 37px;
    }

    .soc-container {
        margin-top: 15px;
    }

    .soc-image {
        max-width: 18px;
    }

    .soc-link:nth-child(1) {
        margin-bottom: -3px;
    }
    .company-header__inner {
        padding: 15px;
        height: auto;
    }
    .mnw-538 {
        order: 1;
    }

    .hash-tags {
        margin: 0 0 15px 0;
        height: 100px;
        text-align: right;
    }
    .list-group-item {
        font-size: 0;
        line-height: 0;
        padding: 5px 0;
        height: 40px;
    }

    .list-group {
        align-items: center;
    }
    .list-group-item {
        background-size: 25px 25px!important;
        background-position: center center!important;
        background-repeat: no-repeat!important;
    }

    [href="#list-item-1"] {
        background-image: url(../images/tab-1.jpg)!important;
    }
    [href="#list-item-2"] {
        background-image: url(../images/tab-2.png)!important;
    }
    [href="#list-item-3"] {
        background-image: url(../images/tab-3.png)!important;
    }
    [href="#list-item-4"] {
        background-image: url(../images/tab-4.jpg)!important;
    }
    [href="#list-item-5"] {
        background-image: url(../images/tab-5.png)!important;
    }
    [href="#list-item-6"] {
        background-image: url(../images/tab-6.png)!important;
    }
    .list-group-item {
        filter: grayscale(1);
    }

    .list-group-item.active {
        filter: none;
    }
    .same-height{
        margin-bottom: 15px;
    }
    .company-general {
        font-size: 14px;
        line-height: 18px;
    }

    .capacity-slider {
        max-width: 66.666667%;
        padding: 0 15px;
        margin-bottom: 30px;
    }

    .full-width-row {
        margin: 0;
    }

    .full-width-row .col-xl-6:first-child {
        margin: 0;
        padding-right: 15px;
    }

    .full-width-row .col-xl-6:last-child {
        margin: 0;
        padding-left: 15px;
    }
    .table--general {
        margin-top: 0;
    }
}
@media (max-width:767px){
    .top-banner h1 {
        position: static;
        transform: none;
        text-align: center;
        margin-bottom: 15px;
    }

    .top-banner {
        padding: 15px;
    }

    .top-banner h1 br {
        display: none;
    }

    .map-block {
        text-align: center;
        margin-bottom: 15px;
    }
    .top-banner__numbers {
        margin-bottom: 16px;
        text-align: center;
    }

    .top-banner__numbers-title {
        font-size: 18px;
        line-height: 24px;
    }

    .top-banner__numbers-title span {
        font-size: 24px;
        line-height: 30px;
    }
    .bg-1 {
        background: none;
        margin: 0;
        padding: 0;
    }
    .top-sub-banner:not(:last-child) {
        margin-bottom: 15px;
    }

    .sub-banner__description {
        font-size: 16px;
        line-height: 18px;
    }

    .bg-2 {
        margin: 0;
        padding: 0;
        background: none;
    }

    .capabilities__description {
        font-size: 16px;
        line-height: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 300px;
    }

    .capabilities__inner {
        padding: 20px 15px 15px;
    }

    .capabilities__inner .col-md-6 {
        margin-bottom: 15px;
    }

    .category-variant, .sub-category-variant {
        font-size: 14px;
        padding: 5px 10px;
    }

    .modal-footer button {
        font-size: 18px;
        height: 42px;
        max-width: 270px;
    }
    .specialization__category {
        height: 187px;
        padding: 43px 5px 30px;
    }

    .specialization__category-name {
        font-size: 16px;
        line-height: 16px;
    }

    .specialization__category-link {
        font-size: 16px;
        line-height: 26px;
        width: 100px;
        height: 26px;
    }
    img.about__images {
        margin: 0 auto 15px;
        display: block;
    }

    .about__header {
        margin-bottom: 15px;
    }
    .developers-slide div {
        padding: 5px;
    }
    .footer-menu a {
        margin-left: 24px;
        font-size: 14px;
        line-height: 16px;
    }
    .copy {
        letter-spacing: -0.35px;
    }

    .pp {
        letter-spacing: -0.6px;
    }

    .illuminator {
        letter-spacing: -0.35px;
    }
    .table--general td, .table--general th {
        width: 50%;
    }

    .table--general tr {
        display: flex;
        flex-wrap: wrap;
    }
    .company-product-name {
        padding: 0 5px;
        text-transform: none;
        font-size: 16px;
    }
}
@media (max-width:575px){
    .carousel-slider .slick-prev {
        left: -15px;
    }

    .carousel-slider .slick-next {
        right: -15px;
    }
    .top-banner {
        background: none;
        box-shadow: none;
        padding: 0;
        border-radius: 0;
    }

    .top-banner h1 {
        text-transform: none;
        line-height: 22px;
    }

    .map-block svg {
        height: auto;
    }


    .top-banner__numbers-title {
        background: white;
        padding-top: 10px;
        border-radius: 10px 10px 0 0;
    }

    .top-banner__numbers-description {
        background: white;
        padding-bottom: 10px;
        border-radius: 0 0 10px 10px;
    }

    .separator--banner {
        height: 0px;
    }


    .separator--bg-1 {
        height: 30px;
    }

    .capabilities__header {
        text-transform: none!important;
        line-height: 20px;
    }

    .capabilities__inner {
        border-radius: 0;
        padding: 0;
        background: none;
    }

    .capability__title {
        text-transform: none!important;
    }

    .dotted li {
        padding-left: 20px;
    }

    .capability {
        box-shadow: none;
    }

    .separator--bg-2 {
        height: 30px;
    }

    .orders__header {
        text-transform: none!important;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .specialization__header {
        text-transform: none!important;
        line-height: 20px;
    }

    .about__header {
        text-transform: none!important;
        line-height: 20px;
    }

    .carousel__header {
        text-transform: none!important;
        line-height: 20px;
    }

    .orders__filter-accept {
        height: 30px;
        width: 100px;
    }

    .orders__filter-title {
        font-size: 17px;
    }

    .modal-header {
        padding: 5px;
        text-transform: none;
        font-size: 20px;
        font-weight: bold;
    }

    .modal-footer {
        padding: 10px 15px;
    }

    .modal-footer button {
        height: 30px;
        line-height: 30px;
        padding: 0;
        margin: 5px 0;
        width: 100px;
        font-size: 16px;
    }

    .modal-body {
        padding: 10px 15px;
    }

    .category-variant,
    .sub-category-variant {
        padding: 2px 5px;
    }
    .orders__filter-all {
        font-size: 18px;
    }

    .orders-item__name {
        font-size: 17px;
        line-height: 20px;
    }

    .orders__list-item {
        margin-bottom: 15px;
    }

    .orders__add {
        margin-top: 15px;
        font-size: 18px;
    }

    .specialization__description {
        font-size: 16px;
        line-height: 18px;
    }

    .specialization__category {
        margin-bottom: 15px;
        height: 335px;
        width: 270px;
        margin-left: auto;
        margin-right: auto;
        padding: 90px 10px 60px;
    }

    .specialization__category-name {
        font-size: 18px;
        line-height: 18px;
    }

    .specialization__category-link {
        height: 30px;
    }

    .specialization__members {
        margin-top: 15px;
    }

    .about__images {
        max-width: 175px!important;
        margin-left: auto;
        margin-right: auto;
    }

    .carousel__header {
        margin-bottom: 5px;
    }

    .carousel-block {
        padding-bottom: 10px;
    }
    .footer__logo-block {
        margin-bottom: 10px;
        text-align: center;
    }

    .footer__phone {
        text-align: center;
        display: block;
        margin-bottom: 15px;
    }

    .footer-menu {
        justify-content: space-between;
        width: 100%;
    }

    .footer-menu li {
        margin-bottom: 5px;
    }

    .footer-menu a {
        margin: 0 5px;
        text-transform: none;
        font-size: 16px;
    }

    .soc-container {
        margin-top: 10px;
        justify-content: center;
        width: 100%;
    }

    .footer__top-line {
        margin-bottom: 15px;
    }

    .copy {
        letter-spacing: 0;
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .pp {
        letter-spacing: 0;
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .illuminator {
        letter-spacing: 0;
        text-align: center;
        font-size: 14px;
        margin-bottom: 0;
    }

    footer {
        padding: 15px 0 20px;
    }
    .verified {
        margin-top: 15px;
    }

    .hash-tags {
        margin-top: 15px;
        text-align: left;
        padding: 0;
        float: none;
    }


    .company__general-information {
        flex-direction: column;
        margin-bottom: 0px!important;
    }


    .company__general-information>div {
        margin-bottom: 5px;
    }

    .company__general-information>a {
        margin-bottom: 5px;
    }

    .contact-the-company {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .add-company-to-favorite {
        margin-bottom: 10px;
    }

    div#list-example {
        margin-bottom: 15px;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .list-group-item {
        font-size: 14px;
        line-height: 16px;
        background: none!important;
        width: auto;
        height: auto;
        padding: 5px 10px;
        flex-grow: 1;
        text-align: center;
    }
    .company-slider-arrow--prev {
        margin: 10px 0;
    }

    button.company-slider-arrow--next {
        margin: 10px 0;
    }

    .capacity-slider {
        max-width: unset;
    }

    .capacity-slider .slick-prev {
        left: -13px;
    }

    .capacity-slider .slick-next {
        right: -13px;
    }

    .company-h2 {
        font-size: 18px;
        line-height: 20px;
    }

    .company-product {
        max-width: 240px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
    }

    .company-product-name {
        text-transform: uppercase;
        padding: 0 15px;
    }
}